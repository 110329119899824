@import "css/init";

// vendor
@import "~bootstrap/scss/bootstrap";
$fa-font-path: "../fonts";
@import "~font-awesome/scss/font-awesome";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~axios-progress-bar/dist/nprogress.css";

// old styles
@import "css/templates/bootstrap";
@import "css/templates/layout";
@import "css/templates/login";
@import "css/templates/sidebar";
@import "css/datas/filter";
@import "css/datas/highlight";
@import "css/datas/after-offset";
@import "css/forms/ace";
@import "css/forms/datetime";
@import "css/forms/multiple";
@import "css/forms/select";
@import "css/tables/table";
@import "css/tables/row-action";

// components
@import "components/Base/Base.scss";
@import "components/Table/styles.scss";
@import "components/Form/styles.scss";
@import "components/Form/Select/styles.scss";
@import "components/DatePicker/styles.scss";
@import "components/Pagination/styles.scss";
@import "components/SearchBar/styles.scss";

// containers
@import "containers/SideBar/styles.scss";
@import "containers/Tail/styles.scss";
@import "src/containers/Node/NodeDetail/styles.scss";
@import "src/containers/Topic/TopicProduce/styles.scss";
@import "containers/Login/styles.scss";

#content {
  transition: all 0.2s ease;

  aside {
    width: 100%;
    left: 0;
  }
}

.khq-data-filter {
  @include media-breakpoint-up(sm) {
    select {
      max-width: 100%;
    }
  }
}

.table-hover tbody tr:hover code:after {
  background: linear-gradient(transparent, $body-bg);
}

#nprogress .bar {
  background: $red;
  height: 4px;
}

#nprogress .peg {
  box-shadow: 0 0 10px $red, 0 0 5px $red;
}

#nprogress .spinner-icon {
  border: solid 4px transparent;
  border-top-color: $red;
  border-left-color: $red;
}


.Toastify__toast-container {
  width: 98% !important;
  top: 1% !important;
  right: 1% !important;

  .Toastify__toast {
    width: 320px;
    top: 1em;
    right: 1em;
    position: absolute;

    &.internal-error {
      width: 98% !important;
      top: 1% !important;
      right: 1% !important;
      overflow: auto;
      cursor: default;

      > div {
        overflow: auto;
      }

      p {
        font-weight: bold;
      }

      .Toastify__toast {
        cursor: default;
      }

      .Toastify__close-button {
        position: fixed;
        background: $red;
        border: 1px solid darken($red, 15);
        border-radius: $border-radius;
        width: 31px;
        right: 25px;
        padding: 4px;
        opacity: 1;
      }
    }
  }
}

