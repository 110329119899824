// Tabs
ul.nav-tabs {
    .nav-link {
        background-color: transparent;
        border: 0 solid;
        border-bottom-width: 4px;
        border-bottom-color: transparent;
        color: $body-color;

        &.active, &:hover.active {
            font-weight: bold;
            color: $primary;
            border-bottom-color: $primary;
            border-bottom-width: 4px;
            background-color: transparent;
        }

        &:hover {
            transition-duration: 0ms;
            border-bottom-color: $gray-400;
            border-bottom-width: 4px;
        }
    }
}

.tabs-container {
    .tab-content {
        padding: 20px;
        border: 1px solid $nav-tabs-border-color;
        background: $gray-900;
        border-top: 0;
    }
}

// Navbar
nav.navbar {
    a.dropdown-toggle {
        white-space: nowrap;
    }

    .dropdown-menu {
        .input-group {
            margin: $dropdown-item-padding-y $dropdown-item-padding-x;
            width: calc(100% - 3rem);
            min-width: 200px;

            input {
                border: 0;
            }
        }
    }
}

// Pagination
.page-item.info {
    .page-link {
        color: color-yiq($secondary);
        @include gradient-bg($secondary);
        white-space: nowrap;
    }
}

form >legend {
    border-bottom: 2px solid $gray-700;
}