a, button, input[type="submit"] {
    transition-duration: 200ms;
}

body {
    min-width: 320px;
}

.title {
    display: flex;
    position: relative;
    width: calc(100% + 40px);
    left: -20px;
    top: -40px;
    height: 94px;
    border-bottom: 4px solid $yellow;
    padding: 20px 20px 0;

    .btn {
        white-space: nowrap;
    }
}

h1 {
    font-family: $font-family-title;
    font-weight: 700;
    font-size: 2rem;
    color: white;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

h3.logo {
    margin-bottom: 0;
    text-align: center;

    img {
        height: 60px;
    }
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.ace_editor {
    font-family: $font-family-monospace;
    background-color: transparent;
    border-radius: $input-border-radius;
    border: 1px solid #262424;
}

.khq-sticky {
    @-moz-document url-prefix() { position: initial }
    position: sticky;
    z-index: 100;
    top: 0;
}

.khq-nav {
    @include media-breakpoint-up(lg) {
        input, button, select, .bootstrap-select{
            margin-right: ($spacer * .5)
        }
    }

    @include media-breakpoint-down(md) {
        input, button, select {
            margin-top: ($spacer * .5)
        }

        .navbar-toggler {
            margin-top: 0;
        }

        input, .bootstrap-select, select {
            width: 100% !important;
        }

        button.btn-primary {
            display: block;
            width: 100%;
        }

        .btn-group {
            width: 100%;
        }
    }

    .btn-group {
        button {
            margin-right: 0;
        }
    }

}

.ace_editor, .table-responsive {
    ::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-width;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(100, 100, 100, 0.9);
    }

    ::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(100, 100, 100, 0.6);
    }
}



#content {
    @include media-breakpoint-up(md) {
        width: calc(100% - #{$menu-width});
    }

    @include media-breakpoint-up(md) {
        margin-left: $menu-width;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    &.no-side-bar {
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    padding: 20px 20px 65px;
    min-height: 100vh;
    transition: none;
}

.turbolinks-progress-bar {
    background-color: $red;
    box-shadow: 0 1px 10px $red;
}

form.khq-form {
    margin-bottom: 25px;
}


form.khq-form .bootstrap-select{
    width: 100% !important;
}

form.khq-form div.khq-submit,
#content aside {
    position: fixed;
    bottom: 0;
    left: 0;
    background: red;
    padding: 5px 15px;
    background: $black;
    border-top: 1px solid $nav-tabs-border-color;
    display: flex;
    justify-content: flex-end;
    width: calc(100vw - 15px);
    z-index: $zindex-fixed;

    @include media-breakpoint-up(md) {
        left: $menu-width;
        width: calc(100vw - #{$menu-width} - 15px);
    }

    > div {
        flex: 1;

        &:first-child {
            flex-grow: 2;
        }

        &:last-child {
            text-align: right;
        }
    }

    .btn {
        white-space: nowrap;
    }
}

.tab-pane form.khq-form div.khq-submit {
    width: calc(100vw - 15px);
    @include media-breakpoint-up(md) {
        width: calc(100vw - #{$menu-width} - 15px);
    }
}

.btn.disabled-black-button {
    color: #b3b3b3;
    background-color: #222;
    border-color: #333;
}
