table {
    tbody {
        tr.khq-data-highlight-row {
            &:hover {
                background: none;
            }

            pre {
                overflow: hidden;
                max-height: none;
                cursor: default;

                code:after {
                    display: none;
                }
            }
        }
    }
}
