.khq-data-filter {
    > nav {
        display: flex;
        order: 2;
    }

    > .navbar-collapse {
        @include media-breakpoint-down(md) {
            order: 3;
        }
    }

    @include media-breakpoint-up(sm) {
        select {
            max-width: 170px;
        }
    }
}