
.khq-offset-navbar{

  max-height:400px;
  overflow: auto;

  .offset-navbar-partition-label{
    white-space: nowrap;
    text-align: right;
    padding-right: 4px;
    width: 75px;
  }
  .offset-navbar-partition-td{
    padding-bottom: 10px;
  }

  .input-group{
    flex-direction: column;
  }

  .input-group table{
    width: 100%;
  }

  .input-group-append {
    margin-left: auto;
  }

  .input-group-append .btn{
    margin-right:0;
    border-radius:0.25rem;

  }

  .input-group .offset-navbar-partition-td .form-control::-webkit-inner-spin-button,
  .input-group .offset-navbar-partition-td .form-control::-webkit-outer-spin-button{
    -webkit-appearance: none;
  }

}