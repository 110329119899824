table {
    td.khq-row-action, th.khq-row-action {
        width: 16px;
    }

    tbody {
        .khq-main-row-action {
            cursor: pointer;
        }
    }
}
