.khq-multiple {
    margin-left: 0;
    > div {
       display: flex;
        margin-bottom:  $form-group-margin-bottom;

        &:first-child {
            flex-grow: 1;
        }

        .row {
            margin-bottom:  0;
            &:last-child {
                margin-left: 0;
                margin-right: 0;
                padding-right: 0;
            }
        }

        .input-class {
            width: 100%;
        }
    }
}
